export default ({campaign, roomClass}) => `
<div class="Campaign ${roomClass}" id="blr" role="banner">
    <div class="Campaign-overlay">
        <div class="row">
            <div class="small-12 columns Campaign-titleContainer">
                <h2 class="Campaign-title ${roomClass}">
                    ${campaign.name.toUpperCase()}
                </h2><hr class="Campaign-titleDivider"/>
            </div>
            <div class="small-12 columns Campaign-container">
                <div class="Campaign-cover">
                    <ul class="large-block-grid-1 Campaign-grid">
                        <li class="Campaign-fullCoverContainer"></li>
                    </ul>
                </div>
                <div class="Campaign-description small-12 columns">
                    ${campaign.description}
                </div>
            </div>
            ${campaign.type === 'BLR' ? `
                <div class="Campaign-linkContainer">
                    ${campaign.items.length > 1 ? `
                        <a href="${window.url(window.routes.mediaId, {id: campaign.items[1].id})}" class="Campaign-audiobookLink" role="link" aria-label="${window.html('hero.viewAudiobookVersion')}">
                            <i class="icon icon-audiobook Campaign-audiobookIcon" aria-hidden="true"></i>&nbsp;<span class="Campaign-audiobookText">${window.html('hero.alsoAvailableAudiobook')}&nbsp;<i class="icon icon-forward-arrow Campaign-arrowIcon" aria-hidden="true"></i></span>
                        </a>
                    ` : ''}
                </div>
            ` : ''}
        </div>
    </div>
</div>
`;
