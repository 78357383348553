import CollectionDefinition from './CollectionDefinition';

export default class PageManager extends Backbone.Model {

    get idAttribute() { return 'pageId'; }

    get isLoaded() {
        return !_.isEmpty(this.attributes);
    }

    // page == room, page has views, one of which is the home-page collections collection
    // ref: /v2/libraries/qa/pages
    fetchRoomById(roomId = 'home') {
        this.url = window.url(window.routes.rest.rooms.get, { roomId });

        return new Promise((resolve, reject) => {
            this.fetch({
                timeout: 10000,
                success: (model, response, options) => resolve(response),
                error: (model, response, options) => reject(response)
            });
        });
    }

    fetchCampaignById(campaignId) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: window.url(window.routes.rest.campaigns.get, { campaignId }),
                dataType: 'json',
                timeout: 10000
            })
                .done((response) => resolve(response))
                .fail((jqHXR) => reject(jqHXR));
        });
    }

    fetchFirstDisplayableCampaign(roomId = 'home') {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: window.url(window.routes.rest.campaigns.getFirstDisplayable, { roomId }),
                dataType: 'json',
                timeout: 10000
            })
                .done((response) => resolve(response))
                .fail((jqHXR) => reject(jqHXR));
        });
    }

    fetchCollectionById(collectionId) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: window.url(window.routes.rest.collections.getById, { id: collectionId }),
                dataType: 'json',
                timeout: 10000
            })
                .done((response) => resolve(new CollectionDefinition(response)))
                .fail((jqHXR, textStatus, errorThrown) => reject(jqHXR));
        });
    }

    getView(viewName) {
        if (!this.isLoaded) { return null; }

        return (viewName || '').toLowerCase() === 'home'
            ? _(this.attributes.views).findWhere({ isDefault: true })
            : _(this.attributes.views).findWhere({ name: viewName });
    }

    getCampaigns() {
        return this.isLoaded && this.attributes.campaigns;
    }
}
