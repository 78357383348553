import ContextMenu from '../ContextMenu';
import TitleAuthor from './TitleInfo/TitleAuthor';
import TitleEdition from './TitleInfo/TitleEdition';
import TitleName from './TitleInfo/TitleName';
import TitleFormatBadge from './TitleInfo/TitleFormatBadge';

export default class TitleInfo extends Backbone.View {
    get className() {
        return 'title-info';
    }

    constructor() {
        super(...arguments);
        this._views = [];

        this.render();
    }

    _reset() {
        this._views.forEach((view) => view.remove());
        this._views = [];
    }

    remove() {
        this._reset();
        super.remove();
    }

    render() {
        this._reset();

        this._views.push(
            (this.titleName = new TitleName({ model: this.model }))
        );

        if (!window.featureManager.isEnabled('geo-idc')) {
            this._views.push(
                (this.contextMenu = new ContextMenu({ model: this.model }))
            );
            this.contextMenu.$el.addClass('text-right');
        }
        if (this.model.isMagazine) {
            this._views.push(
                (this.titleEdition = new TitleEdition({ model: this.model }))
            );
        } else if (!window.featureManager.isEnabled('geo-idc')) {
            this._views.push(
                (this.titleAuthor = new TitleAuthor({ model: this.model }))
            );
        }

        this._views.push(
            (this.titleFormatBadge = new TitleFormatBadge({
                typeId: this.model.get('type').id,
            }))
        );

        this.$el.empty();
        this.$el.append(...this._views.map((view) => view.$el));
    }
}
