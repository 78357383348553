import Popover from '../../components/popover/popover';
import { toStaticUrl } from '../../../../lib/assetMapper';

export default class NeedMobileNumberPopover {
    constructor(el) {
        this.template = `<p>${html('getCard.numberVerificationInfo')}</p>
        <a href="https://company.cdn.overdrive.com/policies/privacy-policy.htm#whatisglc" class="secondary-color" target="_blank" rel="noreferrer">${html(
            'getCard.learnMore'
        )} 
            <i class="icon-forward-arrow chevron" aria-hidden="true"></i>
        </a>
        <div class="getACard-map-image-div"><img src="${toStaticUrl(
            'img/map.svg'
        )}"></div>`;

        this.el = el;
    }

    render() {
        return new Popover({
            target: this.el,
            contents: this.template,
            width: 400,
        });
    }
}
