import TitleCardTemplate from './templates/TitleCard.html';

import TitleInfo from './views/TitleInfo';
import TitleActionButton from './TitleActionButton';
import WishListButton from './WishListButton';
import TitleHeaderBar from './TitleHeaderBar';
import Cover from './Cover';

export default class TitleCard extends Backbone.View {
    get className() {
        return 'title-container title';
    }

    template = TitleCardTemplate;

    constructor({ coverWidths, isInHero = false }) {
        super(...arguments);
        this._views = [];

        this.coverWidths = coverWidths;
        this.isInHero = isInHero;

        this.render();
    }

    render() {
        const { model, coverWidths, isInHero } = this;

        this.$el.empty();

        this._views.forEach((view) => view.remove());
        this._views = [];

        const $tmp = $(
            this.template({
                geoIdcClass: window.featureManager.isEnabled('geo-idc')
                    ? 'geoIdc'
                    : '',
            })
        );

        // TODO: This will not work  with CSS transition animations
        if (this.model.isPlaceholder) {
            this.$el.addClass('placeholder');
        }

        if (!window.featureManager.isEnabled('geo-idc')) {
            this._views.push(
                (this.titleHeaderBar = new TitleHeaderBar({
                    model,
                    el: $tmp.find('.TitleHeaderBar'),
                }))
            );
        }

        this._views.push(
            (this.cover = new Cover({
                model,
                el: $tmp.find('.CoverImage'),
                coverWidths,
                isInHero,
            }))
        );

        this._views.push(
            (this.titleInfo = new TitleInfo({
                model,
                el: $tmp.find('.TitleInfo'),
            }))
        );

        this._views.push(
            (this.titleActionButton = new TitleActionButton({
                model,
                el: $tmp.find('.TitleAction'),
            }))
        );

        if (
            !window.featureManager.isEnabled('sampleOnlyMode') &&
            !(
                window.featureManager.isEnabled('magazine-rollup') &&
                this.model.isMagazine
            ) &&
            !window.featureManager.isEnabled('disableWishlist')
        ) {
            this._views.push(
                (this.wishlistButton = new WishListButton({
                    model,
                    el: $tmp.find('.TitleWishlist'),
                    type: 'icon-only',
                }).render())
            );
        }

        this.$el.append($tmp);

        return this;
    }

    remove() {
        this._views.forEach((view) => view.remove());
        super.remove();
    }
}
