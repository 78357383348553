import svgLoader from '../../util/svgLoader';

export default class Popover extends Backbone.View {

    constructor(opts){
        super(...arguments);

        if(!opts.target){
            throw new Error('you must specify a target');
        }

        this.contents = opts.contents;
        this.target = opts.target;
        this.enabled = false;
        this.className = opts.className;
        this.requestedWidth = opts.width || 300;
        this.renderSvgOnToggle = opts.renderSvgOnToggle || false;
        this.actions = opts.actions;
        this.hideActions = opts.hideActions;

        this.target.on('click', (e) => {
            this.toggle();
            e.stopPropagation();
        });
    }

    getParams() {
        let topMargin = 6;
        let sideMargin = 5;
        let caretSpacer = 4;
        let targetOffset = this.target.offset();
        let targetWidth = this.target.width();
        targetOffset.right = targetOffset.left + targetWidth;
        let targetHeight = this.target.height();
        let viewportWidth = $(window).width();
        let viewportHeight = $(window).height();
        let width = Math.min(this.requestedWidth - (2 * sideMargin), viewportWidth);
        let xOverlap = width - targetWidth;
        let spaceToLeft = targetOffset.left;
        let spaceToRight = viewportWidth - targetOffset.right;
        let leftHasRoom = spaceToLeft >= (xOverlap / 2);
        let rightHasRoom = spaceToRight >= (xOverlap / 2);


        // prefer centering on the target.  This is different than the foundation popovers but much easier to reason about.
        let left, right, top, bottom, caretLeft, caretRight, alignmentClass;

        if (leftHasRoom && rightHasRoom){
            // horizontally centered on the target element
            left = targetOffset.left - (xOverlap / 2);
            right = viewportWidth - targetOffset.right - (xOverlap / 2);
            caretLeft = width / 2;
            alignmentClass = 'popoverCenter';
        } else if (spaceToRight >= xOverlap){
            // align the left edge to the target's left edge
            left = targetOffset.left;
            right = viewportWidth - targetOffset.right - xOverlap;
            caretLeft = caretSpacer;
            alignmentClass = 'popoverLeft';
        } else if (spaceToLeft >= xOverlap){
            // align the right edge to the target's right edge
            left = targetOffset.left - xOverlap;
            right = viewportWidth - targetOffset.right;
            caretRight = caretSpacer;
            alignmentClass = 'popoverRight';
        } else {
            // the viewport is narrower than the desired popover width. Fill the available space.
            left = 0;
            right = 0;
            caretLeft = targetOffset.left + (targetWidth / 2);
            alignmentClass = 'popoverFit';
        }

        let openUp = ((this.target[0].getBoundingClientRect().top + (targetHeight / 2)) / viewportHeight) > 0.666666;
        if (!openUp){
            top = targetOffset.top + topMargin + targetHeight;
        } else {
            bottom = viewportHeight - targetOffset.top + topMargin;
        }

        return {
            main: {
                top,
                bottom,
                left,
                right
            },
            caret: {
                left: caretLeft,
                right: caretRight
            },
            directionClass: openUp ? 'popoverUp' : 'popoverDown',
            alignmentClass
        };
    }


    render() {
        let params = this.getParams();

        this.$el.empty();
        this.$el = $(`
            <div class="${this.className} popoverCanvas ${params.directionClass} ${params.alignmentClass}">
                <div class="popover" style="
                    ${params.main.top ?  `top:${params.main.top}px;` : ''}
                    ${params.main.bottom ?  `bottom:${params.main.bottom}px;` : ''}
                    left:${params.main.left}px;
                    right:${params.main.right}px"
                    aria-live="polite">
                        <div class="popoverCaret" style="
                                ${params.caret.right ?  `right:${params.caret.right}px;` : ''}
                                ${params.caret.left ?  `left:${params.caret.left}px;` : ''}" >
                            <div class="popoverCaretTip"></div>
                        </div>
                        ${this.contents}
                </div>
            </div>
        `);

        $('body').append(this.$el);
        $('body').click(() => this.hide());
    }

    hide() {
        this.$el.remove();
        this.enabled = false;
    }

    show(){
        this.render();
        if(this.renderSvgOnToggle){
            svgLoader();
        }
        if(this.actions){
            this.actions();
        }
        this.enabled = true;
    }

    toggle(){
        if (this.enabled){
            if(this.hideActions){
                this.hideActions();
            }
            this.hide();
        }
        else {
            this.show();
        }
    }
}
