import MediaParameters from '../../../../models/dao/MediaParameters';
import TitleCollection from './models/TitleCollection';

export default class MediaInterface {

    constructor() {
        _.extend(this, Backbone.Events);

        this.items          = new TitleCollection();
        this.facets         = {};
        this.sortOptions    = [];
    }

    quickSearch(term, options) {
        return this._fetch(_.extend({}, { query: term }, options));
    }

    search(parameters, options) {
        return this._fetch(_.extend({}, parameters, options));
    }

    fetchCollectionById(id, options) {
        return this._fetch(_.extend({}, { collectionId: id }, options));
    }

    fetchCollectionByParameters(collectionFilters, options) {
        return this._fetch(_.extend({}, collectionFilters, options));
    }

    /** Utility function to simulate a data call, but deliberatley return an empty result set */
    fetchEmptyResponse() {
        this.trigger('sync:start', this, { mediaParameters: null });

        let emptyResponse = {
            items       : [],
            facets      : {},
            sortOptions : []
        };

        this._fetchDone(emptyResponse, 'synthetic');

        this.trigger('sync:end', this);

        return Promise.resolve(emptyResponse);
    }

    /**
     * @param {MediaParameters} parameters
     * @memberof MediaInterface
     */
    _fetch(parameters) {

        if (!parameters){
            parameters = {};
        }

        // if a title id is specificed then no facets will be calculated regardless of the params sent.
        if (!parameters.includedFacets && !parameters.titleId){
            parameters.includedFacets = window.OverDrive.supportedFacets;
        }

        if (window.featureManager.isEnabled('LuckyDay') && parameters.shuffle){
            const sorts = ['mostpopular', 'releasedate', 'title', 'author', 'newlyadded', 'lowcirculating', 'mostpopular-site'];
            parameters.sortBy = sorts[Math.floor(Math.random() * sorts.length)];
        }

        let mediaParameters = new MediaParameters(parameters);

        if (!!window.OverDrive.showOnlyAvailable) {
            mediaParameters.showOnlyAvailable = true;
        }

        this.trigger('sync:start', this, { mediaParameters });

        return new Promise((resolve, reject) => {
            $.ajax({
                url: window.url(window.routes.rest.media.index),
                method  : 'POST',
                dataType: 'json',
                contentType: 'application/json; charset=utf-8',
                data    : JSON.stringify(mediaParameters.serialize()),
                timeout: 10000
            })
            .done((data, textStatus, jqXHR) => {
                if (window.featureManager.isEnabled('LuckyDay') && parameters.shuffle){
                    data.items = data.items && _.shuffle(data.items);
                }
                if (window.featureManager.isEnabled('only-show-owned-content') && data.items) {
                    data.items = data.items.filter((x) => x.isOwned === true);
                }
                this._fetchDone(data, textStatus, jqXHR);
                this.trigger('sync:end', this);
                resolve(data);
            })
            .fail((jqXHR, textStatus, errorThrown) => {
                this._fetchFail(jqXHR, textStatus, errorThrown);
                this.trigger('sync:end', this);
                reject(jqXHR);
            });
        });
    }

    _fetchDone(data, textStatus, jqXHR) {
        this.items.reset(data.items);
        this.trigger('sync:success', this, { data, textStatus, jqXHR });
    }

    _fetchFail(jqXHR, textStatus, errorThrown) {
        this.trigger('sync:fail', this, { jqXHR, textStatus, errorThrown });
    }
}
