export default class ColorUtility {

    // see _vars.scss for the corresponding css color vars
    //
    static get highContrastBlue(){
        return '#0000FF';
    }

    static get highContrastBlueHover(){
        return '#0000F5';
    }

    static get highContrastYellow(){
        return '#FFFF00';
    }

    static get highContrastYellowHover() {
        return '#F5E600';
    }

// credit: https://stackoverflow.com/a/39077686
    // This will accept three character shorthand hex colors as well as the standard six character format.
    static hexToRGB(hexColor){
        return hexColor.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
                ,(m, r, g, b) => '#' + r + r + g + g + b + b)
            .substring(1).match(/.{2}/g)
            .map(x => parseInt(x, 16));
    }

    static rgbToHex(rgb) {
        let nToHex = function (n) {
            let hex = parseInt(n, 10).toString(16);
            return hex.length === 1 ? '0' + hex : hex;
        };
        return '#' + nToHex(rgb[0]) + nToHex(rgb[1]) + nToHex(rgb[2]);
    }

    static getTextColorFromRGB(backgroundRGB){
        // Standard contrast calculation based on the Y component of the YIQ color space.  Libby uses the same default Luma threshold.
        // https://github.com/bksh/dewey-js/blob/ebb7189241c5eade8000776d97cd5451bf643e3d/src/app/base/cover-guru.js#L10
        // https://en.wikipedia.org/wiki/YIQ#From_RGB_to_YIQ
        const LUMA_THRESHOLD_YIQ  = 190;
        return (backgroundRGB[0] * 299 + backgroundRGB[1] * 587 + backgroundRGB[2] * 114) / 1000 > LUMA_THRESHOLD_YIQ ? '#000000' : '#ffffff';
    }

    static getTextColorFromHex(backgroundHex){
        let rgb = ColorUtility.hexToRGB(backgroundHex);
        return ColorUtility.getTextColorFromRGB(rgb);
    }

    static shadeColorHex(hexColor, percent){
        let rgb = ColorUtility.hexToRGB(hexColor);
        let shadedRGB = ColorUtility.shadeColor(rgb, percent);
        return ColorUtility.rgbToHex(shadedRGB);
    }

    static shadeColor(rgb, percent){
        percent = Math.abs(percent);
        let hsl = ColorUtility.rgbToHsl(rgb[0], rgb[1], rgb[2]);
        let l = hsl[2];
        if (l >= 0.5){
            percent *= -1;
        }
        l = l + percent;
        hsl[2] = l;
        return ColorUtility.hslToRgb(hsl[0], hsl[1], hsl[2]);
    }

    /**
     * Credit: taken from https://stackoverflow.com/a/9493060
     *
     * Converts an HSL color value to RGB. Conversion formula
     * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
     * Assumes h, s, and l are contained in the set [0, 1] and
     * returns r, g, and b in the set [0, 255].
     *
     * @param   {number}  h       The hue
     * @param   {number}  s       The saturation
     * @param   {number}  l       The lightness
     * @return  {Array}           The RGB representation
     */
    static hslToRgb(h, s, l){
        var r, g, b;

        if (s === 0){
            r = g = b = l; // achromatic
        } else {
            let hue2rgb = function (p, q, t) {
                if (t < 0) {
                    t += 1;
                }
                if (t > 1) {
                    t -= 1;
                }
                if (t < 1 / 6) {
                    return p + (q - p) * 6 * t;
                }
                if (t < 1 / 2) {
                    return q;
                }
                if (t < 2 / 3) {
                    return p + (q - p) * (2 / 3 - t) * 6;
                }
                return p;
            };

            var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
            var p = 2 * l - q;
            r = hue2rgb(p, q, h + 1 / 3);
            g = hue2rgb(p, q, h);
            b = hue2rgb(p, q, h - 1 / 3);
        }

        return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
    }

    /**
     * Credit: taken from https://stackoverflow.com/a/9493060
     *
     * Converts an RGB color value to HSL. Conversion formula
     * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
     * Assumes r, g, and b are contained in the set [0, 255] and
     * returns h, s, and l in the set [0, 1].
     *
     * @param   {number}  r       The red color value
     * @param   {number}  g       The green color value
     * @param   {number}  b       The blue color value
     * @return  {Array}           The HSL representation
     */
    static rgbToHsl(r, g, b){
        r = r / 255;
        g = g / 255;
        b = b / 255;
        let max = Math.max(r, g, b);
        let min = Math.min(r, g, b);
        let h, s, l = (max + min) / 2;

        if (max === min){
            h = s = 0; // achromatic
        } else {
            var d = max - min;
            s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
            switch (max){
                case r: h = (g - b) / d + (g < b ? 6 : 0); break;
                case g: h = (b - r) / d + 2; break;
                case b: h = (r - g) / d + 4; break;
            }
            h /= 6;
        }

        return [h, s, l];
    }

}
