import TitleNameTemplate from '../../templates/TitleName.html';

export default class TitleName extends Backbone.View {
    constructor() {
        super(...arguments);

        this.template = TitleNameTemplate;
        this.render();
    }

    _assembleLabel() {
        let labelParts = [];

        labelParts.push(this.model.get('title'));

        if (!!this.model.get('firstCreatorName')) {
            labelParts.push(window.html('byLine', {creator: this.model.isMagazine ? this.model.get('publisher').name : this.model.get('firstCreatorName')}));
        }

        labelParts.push(this.model.availabilityText());

        labelParts.push(this.model.get('type').name);

        return labelParts.join(' ');
    }

    render() {
        const viewData = {
            titleId   : this.model.id,
            href      : this.model.titleDetailsUrl,
            ariaLabel : _.escape(this._assembleLabel()),
            title     : this.model.get('title')
        };
        
        this.setElement($(this.template(viewData)));
    }
}

