export default class TitleActionButton extends Backbone.View {
    constructor({ type = 'link', action, style }) {
        super(...arguments);

        this.type = type;

        this.hasCustomAction = false;
        if (!!action) {
            this.action = action;
            this.hasCustomAction = true;
        }

        this.style = style;

        this.$button = $('<button />');

        this.listenTo(this.model, 'change:titleState', this.render);

        this.render();
    }

    get isButton() {
        return this.type === 'button';
    }

    get isLink() {
        return this.type === 'link';
    }

    get state() {
        return this.model.get('titleState');
    }

    render() {
        this.$button.off();
        this.$el.off();

        let attributes = this.getButtonAttrs();
        if (_(attributes.href).isEmpty()) {
            this.$button = $('<button />', attributes);
        } else {
            this.$button = $('<a />', attributes);
        }

        // Attach event handlers if either is true
        // - Button is not a link
        // - Custom action has been provided
        if (_(attributes.href).isEmpty() || this.hasCustomAction) {
            this._attachEventHandlers(this.$button);
        }

        this.$el.empty().append(this.$button);

        return this;
    }

    waiting(isWaiting = true) {
        if (isWaiting) {
            this.$button
                .off('.titleActionButton')
                .addClass('waiting')
                .attr('disabled', isWaiting)
                .append('<div class="spinner"></div>');
        } else {
            this.$button
                .removeClass('waiting')
                .attr('disabled', isWaiting)
                .find('.spinner')
                .remove();

            this._attachEventHandlers(this.$button);
        }

        return this;
    }

    isSelectEvent = (e) =>
        (e.type === 'click' || e.which === 13 || e.which === 32) &&
        (e.preventDefault() || true);

    _attachEventHandlers($el) {
        $el.off('.titleActionButton');

        $el.on('click.titleActionButton', (e) => {
            this.isSelectEvent(e) && this.action(e);
        });
        $el.on('keydown.titleActionButton', (e) => {
            this.isSelectEvent(e) && this.action(e);
        });
    }

    action(event) {
        this.isSelectEvent(event) && this._triggerTitleAction();
    }

    _triggerTitleAction() {
        this.model.trigger('title:action', {
            action: this.state,
            model: this.model,
        });
    }

    _getBorrowButtonText() {
        if (window.featureManager.isEnabled('geo-idc')) {
            return this.model.attributes.type.id === 'audiobook'
                ? window.html('listen')
                : window.html('read');
        }
        return window.html('borrow');
    }

    _getBorrowButtonAriaLabel(title) {
        if (window.featureManager.isEnabled('geo-idc')) {
            return this.model.attributes.type.id === 'audiobook'
                ? window.html('listenToTitle', { title })
                : window.html('readTitle', { title });
        }
        return window.html('borrowTitle', { title });
    }

    getButtonAttrs() {
        let buttonAttrs = {
            'data-media-id': this.model.id,
            'data-type': this.model.attributes.type.id,
            'data-type-name':
                (this.model.attributes.type.id &&
                    window.html(this.model.attributes.type.id)) ||
                '',
            html: (this.$button.html && this.$button.html()) || '',
            tabindex: 0,
        };

        let title = this.model.attributes.title;

        let classes = ['TitleActionButton', 'u-allCaps'];

        if (this.isButton) {
            classes.push(...['button', 'radius', 'is-button', 'secondary']);
        }

        if (this.style === 'fill') {
            classes.push('fill');
        }

        switch (this.state) {
            case 'unavailable':
                classes.push('is-unavailable js-unavailable disabled');
                if (window.featureManager.isEnabled('geo-idc')) {
                    classes.push('visible-unavailable');
                }
                buttonAttrs.html = `<span>${window.html(
                    'availabilityUnavailable'
                )}</span>`;
                break;
            case 'nook':
                classes.push('is-nook js-nook');
                buttonAttrs.html = `<span>${window.html('sendToNook')}</span>`;
                break;
            case 'preview':
                if (this.isLink) {
                    classes.push('secondary-color');
                }

                classes.push('is-preview js-preview secondary');
                buttonAttrs.html = `<span>${window.html(
                    'demoMode.preview'
                )}</span>`;
                buttonAttrs['aria-label'] = window.html(
                    'demoMode.previewTitle',
                    { title }
                );
                break;
            case 'borrow':
                if (this.isLink) {
                    classes.push('secondary-color');
                }

                classes.push('is-borrow js-borrow secondary');
                buttonAttrs.html = `<span>${this._getBorrowButtonText()}</span>`;
                buttonAttrs['aria-label'] =
                    this._getBorrowButtonAriaLabel(title);
                break;
            case 'borrowed':
                if (window.featureManager.isEnabled('geo-idc')) {
                    classes.push('is-openBook big');
                    buttonAttrs.html = `<span>${window.html(
                        'openBook'
                    )}</span>`;
                    buttonAttrs.href = window.url(window.routes.mediaDownload, {
                        format: this.model.attributes.formats[0].id,
                        mediaId: this.model.id,
                    });
                    buttonAttrs['aria-label'] = window.html('openBookLabel', {
                        titleName: title,
                    });
                    buttonAttrs.target = '_blank';
                    buttonAttrs.rel = 'noreferrer';
                } else {
                    classes.push('is-borrowed js-borrowed');
                    buttonAttrs.html = `<span>${window.html(
                        'goToLoans'
                    )}</span>`;
                    buttonAttrs.href = window.url(window.routes.accountLoans);
                }
                break;
            case 'hold':
                if (this.isLink) {
                    classes.push('secondary-color-hover');
                }

                classes.push('is-hold js-hold');
                buttonAttrs.html = `<span>${window.html('placeAHold')}</span>`;
                buttonAttrs['aria-label'] = window.html('placeAHoldOnTitle', {
                    title,
                });
                break;
            case 'onHold':
                if (this.isButton) {
                    classes.push('primary');
                }

                classes.push('is-onHold js-onHold');
                buttonAttrs.html = `<span>${window.html('goToHolds')}</span>`;
                buttonAttrs.href = window.url(window.routes.accountHolds);
                break;
            case 'renew':
                classes.push('is-renew renewalButton');
                classes = classes.filter((c) => c !== 'u-allCaps');
                buttonAttrs.html = `<span><i class="icon icon-renew" aria-hidden="true"></i>${window.html(
                    'renewal.renewTitle',
                    { title: '' }
                )}</span>`;
                break;
            case 'requestAgain':
                classes.push('is-requestAgain renewalButton');
                classes = classes.filter((c) => c !== 'u-allCaps');
                buttonAttrs.html = `<span><i class="icon icon-renew" aria-hidden="true"></i>${window.html(
                    'accountLoans.requestAgain'
                )}</span>`;
                break;
            case 'requested':
                classes.push('is-requested renewalButton');
                classes = classes.filter((c) => c !== 'u-allCaps');
                buttonAttrs.html = `<span><i class="icon icon-renew" aria-hidden="true"></i>${window.html(
                    'accountLoans.requested'
                )}</span>`;
                break;
            case 'renewed':
                classes.push('is-renewed renewalButton');
                classes = classes.filter((c) => c !== 'u-allCaps');
                buttonAttrs.html = `<span><i class="icon icon-renew" aria-hidden="true"></i>${window.html(
                    'accountLoans.renewed'
                )}</span>`;
                break;
            case 'listenNow':
                classes.push('is-listenNow');
                buttonAttrs.html = `<span>${window.html('listenNow')}</span>`;
                buttonAttrs.href = window.url(window.routes.mediaDownload, {
                    format: 'audiobook-overdrive',
                    mediaId: this.model.id,
                });
                buttonAttrs.target = '_blank';
                buttonAttrs.rel = 'noreferrer';
                break;
            case 'readNow':
                classes.push('is-readNow');
                buttonAttrs.html = `<span>${window.html('readNow')}</span>`;
                buttonAttrs.href = window.url(window.routes.mediaDownload, {
                    format: 'ebook-overdrive',
                    mediaId: this.model.id,
                });
                buttonAttrs.target = '_blank';
                buttonAttrs.rel = 'noreferrer';
                break;
            case 'addToApp':
                if (this.isLink) {
                    classes.push('secondary-color-hover');
                }

                classes.push('is-addToApp');
                buttonAttrs.html = `<span>${window.html('addToApp')}</span>`;
                buttonAttrs.href = window.url(window.routes.mediaDownload, {
                    format: this.model.get('addToAppFormat'),
                    mediaId: this.model.id,
                });
                break;
            case 'waiting':
                if (this.isLink) {
                    let dotSpinner = $(window.primaryActionLoadingGif);
                    dotSpinner.css('width', 'inherit');
                    buttonAttrs.html = dotSpinner;
                    break;
                }

                classes.push('waiting secondary');
                buttonAttrs.disabled = true;
                buttonAttrs.html =
                    '<span>working</span><div class="spinner"></div>';
                break;
        }

        buttonAttrs.class = classes.join(' ');

        return buttonAttrs;
    }
}
