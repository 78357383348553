export default ({coverGridSizeClass, collectionTitle, collectionDescription, collectionId, coversHtml, bannerBackgroundColor, bannerTextColor, isHero, useFallbackDisplay, fallBackGridWidth}) => `
<div class="slantedCollectionHighlight">
    <div class="mainBox ${isHero ? 'hero' : ''}">
        <div class="textBlockOuter" style="background-color:${bannerBackgroundColor};color:${bannerTextColor}">
            <div class="textBlockInner invisible">
                <h2 class="headingWrapper"><a class="title" href="${window.url(window.routes.collectionById, {collectionId: collectionId})}">${collectionTitle}</a></h2>
                <div class="description">${collectionDescription}</div>
                <a class="collectionLink horizontal" href="${window.url(window.routes.collectionById, {collectionId: collectionId})}">${window.text('seeAll')}<i class="icon-forward-arrow"/></a>
            </div>
        </div>
        <div class="titleCovers ${coverGridSizeClass}">
             <div class="gridWrapperOuter">
                    <div class="gridWrapperAspect">
                        ${useFallbackDisplay ?
                        `<ul class="fallbackCoverGrid small-block-grid-${fallBackGridWidth}">
                            ${coversHtml.map((html) => `<li class="coverImageContainerContainer">${html}</li>`).join('')}
                        </ul>` :
                        `<div class="gridInner">
                            ${coversHtml.map((coverHtml, index) => `
                            <div class="c${index + 1}">
                                ${coverHtml}
                            </div>`).join('')}
                        </div>`}
                    </div>
                </div>
        </div>
    </div>
    <a class="collectionLink vertical" href="${window.url(window.routes.collectionById, {collectionId: collectionId})}">${window.text('seeAll')}<i class="icon-forward-arrow"/></a>
</div>
`;

