export default () => `
<div class="row">
    <div class="row">
        <div class="large-5 small-8 small-centered columns">
            <div class="error-container text-center" style="margin-top:70px">
                <div data-alert class="alert-box alert radius">
                    ${window.text('error.errorContactSupport', {link: `<a class="bold" href="${window.url(window.routes.supportMembers)}" tabindex="0">${window.html('pleaseContactSupport')}</a>`})}
                </div>
            </div>
        </div>
    </div>
</div>
`;
