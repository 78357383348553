const { Logger, Waypoint, html } = window;
const { isAuthenticated, hasLinkedCards } = window.OverDrive;

import '../../util/Promise';
import ErrorTemplate from '../../components/common/templates/Error.html';

import ProcessForwardedAction from '../../util/title-actions/processForwardedAction';
import HashManager from '../../components/common/HashManager';
import TitleCollection from '../media/models/TitleCollection';

import PatronTitleStatus from '../../models/PatronTitleStatus';

import PageManager from './PageManager';
import CollectionManager from './CollectionManager';
import CollectionHighlightFactory from './CollectionHighlightFactory';
import Campaign from '../campaign/views/Campaign';

import LibbyToutTemplate from '../libby_tout/LibbyTout.html';
import QueryString from 'querystring';

export default class PageView extends Backbone.View {
    // LHTNG-2082: Visual palceholder for Hero section
    generateHeroPlaceholderEl() {
        return $('<div class="Hero" style="min-height: 480px" />');
    }

    constructor({ viewId, room, bus, featureManager, patronSettings }) {
        super(...arguments);

        this.viewId = viewId;
        this.room = room;
        this.bus = bus;
        this.featureManager = featureManager;
        this.appPromoManager = window.appPromoManager;
        this.patronSettings = patronSettings;
        this.pageSize = window.OverDrive.isTolinoDevice ? 2 : 10;
        this.lazyWaypointIndex = 2;

        this.pageManager = new PageManager();
        this.collectionManager = new CollectionManager();
        this.views = [];

        this.collectionErrors = [];

        // Provide a way to simulate collections and campaigns failing to load.  This helps with testing dynamic
        // collection loading logic.
        this.collectionIndexesToFail = null;
        this.failCampaign = false;

        let rawParams =
            window.location.search[0] === '?'
                ? window.location.search.substr(1)
                : window.location.search;
        let queryParams = QueryString.parse(rawParams);

        let collectionsToFail = queryParams.collectionsToFail;
        if (collectionsToFail && collectionsToFail.length > 0) {
            this.collectionIndexesToFail = collectionsToFail
                .split(',')
                .map((val) => parseInt(val, 10));
        }

        this.failCampaign = Boolean(queryParams.failCampaign);

        let forcedSlantedGridCollections =
            queryParams.forcedSlantedGridCollections;
        if (
            forcedSlantedGridCollections &&
            forcedSlantedGridCollections.length > 0
        ) {
            this.forcedSlantedGridCollectionIndexes =
                forcedSlantedGridCollections
                    .split(',')
                    .map((v) => parseInt(v, 10));
        }

        this.forceAllSlantedGridCollections = Boolean(
            queryParams.forceAllSlantedGridCollections
        );

        let slantedGridTitleCountOverride =
            queryParams.slantedGridTitleCountOverride;
        if (slantedGridTitleCountOverride) {
            this.slantedGridTitleCountOverride = slantedGridTitleCountOverride;
        }

        this.update();
    }

    update() {
        this.$el.empty();

        this.views.forEach((view) => view.remove());
        this.views = [];

        this.pageManager
            .fetchRoomById((this.room && this.room.id) || 'home')
            .then(() => {
                this.views = this.initViews();
                this.unrenderedViews = this.views.slice();
                this._loadPage();
            });
    }

    _loadPage() {
        let isFirstPage = this.unrenderedViews.length === this.views.length;

        this._renderPage(
            this.pageSize,
            isFirstPage ? this.lazyWaypointIndex : 0
        );

        if (isFirstPage && !window.OverDrive.isTolinoDevice) {
            this._renderLibbyTout();
        }
    }

    _renderPage(rowCount, wayPointIndex = 0) {
        if (wayPointIndex && wayPointIndex < rowCount - 1) {
            // Paint the whole set of collections right away so scrolling speed is consistent
            // before and after you trigger the waypoint for lazy-loading.
            this._paintCollections(this.unrenderedViews.slice(0, rowCount));

            this._fetchRows(wayPointIndex + 1).then((collections) => {
                this._updatePatronTitleStatus(collections);

                // Should this really be here?  We can just do it right away if we want at the cost of _potentially_ one
                // extra patron title status call.  Meh.
                this._performForwardedAction();

                if (this.views[wayPointIndex]) {
                    let $el = this.views[wayPointIndex].$el;

                    let waypoint = $('<div></div>');
                    $el.after(waypoint);

                    let breakpoint = new Waypoint.Inview({
                        element: waypoint,
                        enter: () => {
                            breakpoint.destroy();

                            if (typeof newrelic !== 'undefined') {
                                newrelic.addPageAction('lazyLoadWaypointHit', {
                                    page: 'homepage',
                                });
                            }

                            this._fetchRows(
                                rowCount - (wayPointIndex + 1)
                            ).then((_collections) => {
                                this._updatePatronTitleStatus(_collections);
                                this._pageLoadComplete();
                            });
                        },
                    });
                }
            });
        } else {
            this._fetchRows(rowCount).then((collections) => {
                this._updatePatronTitleStatus(collections);
                this._pageLoadComplete();
            });
        }
    }

    _fetchRows(rowCount, collectionsDone = []) {
        let views = this.unrenderedViews.splice(0, rowCount);
        this._paintCollections(views);

        return new Promise((resolve) => {
            this._syncRows(views, collectionsDone).then((result) => {
                if (result.failed) {
                    this._fetchRows(result.failed, collectionsDone).then(
                        (collections) => {
                            resolve(collections);
                        }
                    );
                } else {
                    resolve(collectionsDone);
                }
            });
        });
    }

    _syncRows(views, collections) {
        let requestQueue = views.map((view) => view.sync());

        return Promise.settle(requestQueue).then((results) => {
            const succeeded = results.filter(
                (result) => result.fulfilled && result.value
            );
            const failed = results.filter(
                (result) => !result.fulfilled || !result.value
            );

            succeeded.map((request) => collections.push(request.value));

            if (failed.length) {
                // Make sure we make errors visible for debugging
                failed.forEach((failure) => {
                    if (failure && failure.value) {
                        console.error('', failure.value);
                    }
                });
            }

            return { failed: failed.length };
        });
    }

    _paintCollections(views) {
        let frag = document.createDocumentFragment();

        views.forEach((view) => {
            if (!view.isPainted) {
                frag.appendChild(view.el);
                view.isPainted = true;
            }
        });

        this.el.appendChild(frag);
    }

    _pageLoadComplete() {
        if (this.unrenderedViews.length > 0) {
            let $loadMore = $(
                `<button class="center u-allCaps button radius no-outline">${html(
                    'loadMore'
                )}</button>`
            ).on('click', () => {
                this._loadPage();
                $loadMore.remove();
            });

            this.$el.append($loadMore);
        }

        if (this.collectionErrors.length > 0) {
            let allCollectionsFailed = this.views.length === 0;
            this._logPageErrors(this.collectionErrors, allCollectionsFailed);

            if (allCollectionsFailed) {
                this.$el.empty().append(ErrorTemplate());
            }
        }

        this.collectionErrors = [];
    }

    _logPageErrors(failed, allFailed) {
        const howMany = allFailed ? 'Total' : 'Partial';
        let errors = [];
        for (let failure of failed) {
            errors.push({
                id: failure.id,
                type: failure.type,
                statusText: failure.statusText,
                status: failure.status,
            });
        }
        let counts = _.countBy(
            errors,
            (error) => error.type + ':' + error.statusText
        );
        Logger.log('error', `${howMany} HomePage Ajax Error`, {
            message: 'HighlightErrorRollup',
            errors: errors,
            counts: counts,
            page: 'home',
        });
    }

    initViews() {
        let views = [];

        let campaigns = this.pageManager.getCampaigns();
        let hideCampaign = this.featureManager.isEnabled(
            'hide-lightning-campaigns'
        );
        let showCampaign =
            campaigns &&
            campaigns.length > 0 &&
            !window.OverDrive.isVisitor &&
            !hideCampaign;
        if (showCampaign) {
            this.campaign = null;
            this.campaignView = new Campaign({
                bus: this.bus,
                currentRoom: this.room,
            });
            this.campaignView.$el.append(this.generateHeroPlaceholderEl());

            views.push(this.campaignView);

            let getCampaign = () =>
                this.pageManager.fetchCampaignById(campaigns[0].id);
            if (window.featureManager.isEnabled('hide-cta-campaigns')) {
                getCampaign = () =>
                    this.pageManager.fetchFirstDisplayableCampaign(
                        (this.room && this.room.id) || 'home'
                    );
            }

            this.campaignView.sync = () =>
                getCampaign().then(
                    (campaign) => {
                        this.campaign =
                            !_(campaign).isEmpty() &&
                            !_(campaign).isEmpty(campaign.items) &&
                            campaign;

                        if (this.campaign && !this.failCampaign) {
                            this.campaignView.campaign = this.campaign;
                            this.campaignView.model.set(this.campaign.items[0]);
                            this.campaignView.model.updatePatronState();
                            this.campaignView.render();

                            return this.campaignView;
                        } else {
                            this.campaignView.remove();
                            this.removeView(this.campaignView);
                            this.campaignView = null;
                        }
                    },
                    (error) => {
                        error.id = campaigns[0].id;
                        error.statusText = !!error.statusText
                            ? error.statusText
                            : 'UnknownStatus';
                        error.type = 'Ajax Error: Campaign';

                        this.campaignView.remove();
                        this.removeView(this.campaignView);

                        this.collectionErrors.push(error);

                        this.campaignView = null;
                        throw error;
                    }
                );
        }

        let view = this.pageManager.getView(this.viewId);

        if (this._shouldTruncateCollections) {
            view.collectionDefinitions = view.collectionDefinitions.slice(
                0,
                10
            );
        }

        this.collectionManager.reset(view.collectionDefinitions);

        this.collectionManager.each((collectionDefinition, index) => {
            let isHero = !showCampaign && index === 0;

            // TODO: Thought is that the TitleCard template will render in "dummy" state
            collectionDefinition.titleCollection.reset([
                { placeholder: true },
                { placeholder: true },
                { placeholder: true },
                { placeholder: true },
                { placeholder: true },
                { placeholder: true },
            ]);

            let viewArgs = {
                model: collectionDefinition,
                bus: this.bus,
                featureManager: this.featureManager,
                room: this.room,
                isHero,
            };

            if (this.slantedGridTitleCountOverride) {
                viewArgs.titleCountOverride =
                    this.slantedGridTitleCountOverride;
            }

            let forceSlantedGridView =
                this.forceAllSlantedGridCollections ||
                (this.forcedSlantedGridCollectionIndexes &&
                    this.forcedSlantedGridCollectionIndexes.includes(index));
            if (forceSlantedGridView) {
                collectionDefinition.shuffle = true;
            }

            let collectionHighlightView = CollectionHighlightFactory.Build(
                collectionDefinition,
                viewArgs,
                forceSlantedGridView
            );
            collectionDefinition.pageSize =
                collectionHighlightView.desiredTitleCount;

            if (isHero) {
                collectionHighlightView.$el.append(
                    this.generateHeroPlaceholderEl()
                );
            }

            views.push(collectionHighlightView.render());

            collectionHighlightView.sync = () =>
                collectionDefinition
                    .fetchCollectionTitles({
                        patronSettings: this.patronSettings,
                        room: this.room,
                    })
                    .then(
                        () => {
                            let simulateFailure =
                                this.collectionIndexesToFail &&
                                this.collectionIndexesToFail.includes(index);
                            if (
                                collectionDefinition.titleCollection.length &&
                                !simulateFailure
                            ) {
                                collectionHighlightView.render();
                                collectionHighlightView.toggleTitleVisibility(
                                    true
                                );

                                return collectionDefinition;
                            } else {
                                // Clean up the failed row
                                collectionHighlightView.remove();
                                this.removeView(collectionHighlightView);
                                throw new Error(
                                    'No collection contents retrieved'
                                );
                            }
                        },
                        (error) => {
                            collectionHighlightView.remove();
                            this.removeView(collectionHighlightView);

                            error.id = collectionDefinition.id;
                            error.statusText = !!error.statusText
                                ? error.statusText
                                : 'UnknownStatus';
                            error.type = 'Ajax Error: Collection';

                            this.collectionErrors.push(error);

                            throw error;
                        }
                    );
        });

        return views;
    }

    removeView(view) {
        this.views = this.views.filter((v) => v !== view);

        if (
            (view instanceof Campaign || (view.isHero && this.views[0])) &&
            !window.OverDrive.isTolinoDevice
        ) {
            this.views[0].makeHero();
            this._renderLibbyTout();
        }
    }

    _updatePatronTitleStatus(views) {
        if (
            window.OverDrive.isAuthenticated &&
            window.OverDrive.hasLinkedCards &&
            views &&
            views.length > 0
        ) {
            let titles = [];

            views.forEach((view) => {
                if (view instanceof Campaign) {
                    titles.push(view.model);
                } else {
                    titles.push(...view.titleCollection.models);
                }
            });

            let patronTitleStatus = new PatronTitleStatus();

            patronTitleStatus.fetchTitleStatus(titles).then(() => {
                patronTitleStatus.updateTitleCollectionState(_(titles));
            });
        }
    }

    _renderLibbyTout() {
        if (this.libbytout) {
            this.libbytout.remove();
        }

        let frag = document.createDocumentFragment();
        let inApp = Cookies.get('omcbrowse') !== undefined;
        let notShownInApp =
            !this.featureManager.isEnabled('try-libby-modal') ||
            (this.featureManager.isEnabled('try-libby-modal') && !inApp);

        if (
            this.featureManager.isEnabled('libbyTout') &&
            this.appPromoManager.isAppPromoEnabled(
                this.appPromoManager.APPLICATIONS.LIBBY
            ) &&
            !window.OverDrive.isKindle &&
            !this.room &&
            notShownInApp
        ) {
            let $tout = $(LibbyToutTemplate(window.OverDrive.optimizeForChina));

            if ((this.viewId || '').toLowerCase() === 'home') {
                $tout = $('<div class="Home-libbyTout" />').append($tout);
            }
            frag.appendChild($tout[0]);
            this.libbytout = $tout;

            $(window).on('resize scroll', () => {
                if (
                    this.isInViewport(
                        this.libbytout.find('.LibbyTout-wayPoint')
                    )
                ) {
                    this.libbytout
                        .find('.libbyIcon-container')
                        .addClass('libbyIcon-animate');
                } else {
                    this.libbytout
                        .find('.libbyIcon-container')
                        .removeClass('libbyIcon-animate');
                }
            });
        }

        this.views[0].$el.after(frag);
    }

    isInViewport(element) {
        let elementTop = element.offset().top;
        let elementBottom = elementTop + element.outerHeight();

        let viewportTop = $(window).scrollTop();
        let viewportBottom = viewportTop + $(window).height();

        if (viewportTop === 0) {
            return false; // fake it when scroll is at the top, but image is still in view
        }

        return elementBottom > viewportTop && elementTop < viewportBottom;
    }

    _performForwardedAction() {
        if (isAuthenticated && hasLinkedCards && HashManager.get('action')) {
            let titles = [];
            if (this.campaignView) {
                titles.push(this.campaignView.model);
            }
            this.collectionManager.each((collectionDefinition) => {
                titles.push(...collectionDefinition.titleCollection.models);
            });
            const forwardedAction = new ProcessForwardedAction(
                new TitleCollection(titles),
                this.bus
            );
            forwardedAction.executeAction();
            window.forwardedAction = forwardedAction;
        }
    }

    get _shouldTruncateCollections() {
        // The OD app on iOS 8 and 9 crashes on the home page if there are too many collections.  LHTNG-2387
        let inApp = Cookies.get('omcbrowse') !== undefined;
        if (inApp && bowser.ios && bowser.osversion) {
            let versionParts = bowser.osversion.split('.');
            if (versionParts && versionParts.length > 0) {
                let majorVersion = parseInt(versionParts[0], 10);
                if (majorVersion && majorVersion < 10) {
                    return true;
                }
            }
        }
        return false;
    }
}
