export default class TitleActions {
    constructor({ bus, context }) {
        this.bus = bus;

        this.context = {
            room: undefined,
            collectionDefinition: undefined,
        };

        _.extend(this.context, context);
    }

    perform(action, titleModel) {
        if (typeof this[`_${action}`] === 'function') {
            this[`_${action}`](titleModel);
        }
    }

    _borrow(titleModel) {
        this.bus.trigger('title:borrow', titleModel, this.context);
    }

    _hold(titleModel) {
        this.bus.trigger('title:hold', titleModel, this.context);
    }

    _wish(titleModel) {
        this.bus.trigger('title:wishlist', titleModel, this.context);
    }

    _nook(titleModel) {
        this.bus.trigger('title:nook', titleModel, this.context);
    }

    _rating(titleModel) {
        this.bus.trigger('title:rating', titleModel, this.context);
    }

    _history(titleModel) {
        this.bus.trigger('title:history', titleModel, this.context);
    }

    _download(titleModel) {
        this.bus.trigger('title:download', titleModel, this.context);
    }

    _preview(titleModel) {
        this.bus.trigger('title:preview', titleModel, this.context);
    }

    _renew(titleModel) {
        this.bus.trigger('title:renew', titleModel, this.context);
    }

    _requestAgain(titleModel) {
        this.bus.trigger('title:requestAgain', titleModel, this.context);
    }

    setContextRoom(room) {
        if (_(room).isEmpty()) {
            delete this.context.room;
        } else {
            this.context.room = room;
        }

        return this;
    }

    setContextCollection(titleCollection) {
        if (_(titleCollection).isEmpty()) {
            delete this.context.titleCollection;
        } else {
            this.context.titleCollection = titleCollection;
        }

        return this;
    }
}
