import CollectionHighlightView      from '../collection_highlight/views/CollectionHighlight';
import SlantedCollectionHighlight   from '../collection_highlight_slanted/SlantedCollectionHightlight';
const {featureManager} = window;

export default class CollectionHighlightViewFactory
{
    static Build(collectionDefinition, viewOptions, forceSlanted = false){
        if ((featureManager.isEnabled('LuckyDay') && collectionDefinition.isLuckyDay) || forceSlanted){
            return new SlantedCollectionHighlight(viewOptions);
        }
        else {
            return new CollectionHighlightView(viewOptions);
        }
    }
}
