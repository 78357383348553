import Title from '../../media/models/Title';
import TitleCard from '../../media/TitleCard';
import TitleActions from '../../../title-actions/TitleActions';
import CampaignTemplate from '../templates/Campaign.html';
import svgLoader from '../../../util/svgLoader';

export default class Campaign extends Backbone.View {

    get roomClass() {
        return this.currentRoom ? `is-${this.currentRoom.themeName}` : '';
    }

    constructor({ bus, currentRoom, campaign }) {
        super(...arguments);

        this.bus            = bus;
        this.currentRoom    = currentRoom;
        this.campaign       = campaign;

        this.model          = this.model || new Title();
        this.titleActions   = new TitleActions({ bus: this.bus });
        this.titleCard      = new TitleCard({ model: this.model });

        this.listenTo(this.model, 'title:action', this._handleTitleAction);
    }

    render() {
        let $campaign = $(CampaignTemplate(this));

        this.model.setContext({
            campaign: this.campaign
        });

        $campaign.find('.Campaign-fullCoverContainer').append(this.titleCard.render().el);

        this.$el.empty().append($campaign);

        svgLoader();
    }

    _handleTitleAction({action, model}) {
        this.titleActions.perform(action, model);
    }
}
