
export default class TitleEdition extends Backbone.View {
    constructor() {
        super(...arguments);
        this.render();
    }

    render() {
        let node = $(`<div class="title-edition">${this.model.get('edition')}</div>`);
        this.setElement(node);
    }
}
