const _ = require('underscore');

/**
 * Defines all the available input parameters for the media endpoint
 * @class MediaParameters
 * ref: https://thunder.api.test.overdrive.com/docs/ui/index#!/Media/V2Media_IndexMedia
 */
module.exports = class MediaParameters {

    constructor() {
        this.query                      = '';
        this.mediaType                  = '';
        this.mediaTypes                 = '';
        this.title                      = '';
        this.series                     = '';
        this.publisherId                = '';
        this.publisherEntityId          = null;
        this.imprintId                  = '';
        this.identifier                 = '';
        this.creator                    = '';
        this.creatorId                  = null;
        this.addedDate                  = '';
        this.availableFirst             = null;
        this.showOnlyAvailable          = false;
        this.showOnlyPrerelease         = false;
        this.showOnlyLuckyDayAvailable  = false;
        this.hasAudioSynchronizedText   = null;
        this.collectionId               = 0;

        // All of the following arrays are string arrays
        this.format                     = [];
        this.subject                    = [];
        this.bisacCode                  = [];
        this.maturityLevel              = [];
        this.language                   = [];
        this.atosLevel                  = [];
        this.lexileScore                = [];
        this.interestLevel              = [];
        this.gradeLevel                 = [];
        this.includedFacets             = [];

        this.sortBy                     = 'relevance';
        this.show                       = 'owned';
        this.page                       = 1;
        this.perPage                    = 24;
        this.includeFacets              = true;
        this.includeNoFormatTitles      = false;
        this.filterCpcTitles            = false;
        this.maxItems                   = 0;

        Object.assign(this, ...arguments);
    }

    applyRoomFiltersAndUserPrefs(roomFilters, userPreferences) {
        if (roomFilters) {
            // Note that the names don't match up so we can't just extend
            if (roomFilters.maturityLevels) {
                this.maturityLevel = roomFilters.maturityLevels;
            }
            if (roomFilters.subjects) {
                this.subject = roomFilters.subjects;
            }
            if (roomFilters.language) {
                this.language = roomFilters.language;
            }
            if (roomFilters.mediaType) {
                this.mediaType = roomFilters.mediaType;
                this.mediaTypes = roomFilters.mediaType;
            }
        }

        if (userPreferences) {
            // There may be more user prefs to apply besides audience-settings but this
            // was the immediate need.  We can add more as they come up.
            if (userPreferences['audience-setting'] && this.maturityLevel.length === 0) {
                let audience = userPreferences['audience-setting'];
                let maturityLevels = [];
                _.each(_.keys(audience), function(key) {
                    if (audience[key] && key !== 'all') {
                        maturityLevels.push(key);
                    }
                });
                if (maturityLevels.length > 0) {
                    this.maturityLevel = maturityLevels;
                }
            }
        }
    }

    serialize() {
        let response = _.extend({}, this);

        // Remove all properties that do not have a value set
        Object.keys(response).forEach((key) => {
            !this._hasValue(response[key]) && delete response[key];
        });

        return response;
    }

    _hasValue(value) {
        return !((_(value).isObject() && _(value).isEmpty()) || !value) || value === false;
    }
};
