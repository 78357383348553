const { html, url, routes } = window;

export default () => `
    ${ window.featureManager.isEnabled('OzoneAuthentication') ?
     `<div class="small-12 iconContainer columns iconPhoneCard svg dynamic-loader" data-src="need-card-icon2.svg"></div>
     <span class="small-12 columns getACard-title-bold primary-color">${ html('getCard.noLibraryCard') }</span>
     <span class="small-12 columns getACard-subtitle-ozone">${ html('getCard.takesAFewSec') } </span>
     <div align="left" class="getACard-linkContainer">
        <a id="needMyMobileNum" class="small-12 columns getACard-needMobile secondary-color"><span class="border">${ html('getCard.whyDoYouNeed') }</span></a>
     </div>
     <a class="small-12 columns getACard-button-small big button radius outline" href="${ url(routes.accountSignIn) }?forward=%2F&showIdcSignUp=true">${ html('getCard.signUp') }</a>
     <a class="small-12 columns getACard-button-small big button radius secondary" href="${ url(routes.accountSignIn) }?forward=%2F" >${ html('getCard.alreadyHaveACard') }</a>`
        :
    `<div class="small-12 iconContainer columns iconPhoneCard svg dynamic-loader" data-src="need-card-icon.svg"></div>
    <span class="small-12 columns getACard-title-bold primary-color">${ html('getCard.needCard') }</span>
    <span class="small-12 columns getACard-subtitle">${ html('getCard.youNeedACard') } <br> ${ html('getCard.getByMobile') } </span>
    <div class="row">
        <a class="small-6 show-for-medium-up columns getACard-start big button radius secondary" href="${ url(routes.accountGetACard) }">${ html('getCard.getCard') }</a>
        <a class="small-6 show-for-medium-up columns big button outline radius getACard-signIn" href="${ url(routes.accountSignIn) }" >${ html('getCard.noIHaveCard') }<i class="icon-forward-arrow" aria-hidden="true"></i></a>
    </div>
    <a class="small-12 columns show-for-small-only getACard-button-small big button radius secondary" href="${ url(routes.accountGetACard) }">${ html('getCard.getCard') }</a>
    <a class="small-12 columns show-for-small-only big button outline radius getACard-button-small" href="${ url(routes.accountSignIn) }" >${ html('getCard.noIHaveCard') }<i class="icon-forward-arrow" aria-hidden="true"></i></a>`
    }
`;
