// ES6 version of settle
Promise.settle = function(promises) {
    class PromiseInspection {
        constructor(fulfilled, value) {
            this.fulfilled = fulfilled;
            this.value = value;
        }
    }

    return Promise.all(promises.map((p) =>
        // make sure any values are wrapped in a promise
        Promise.resolve(p).then((val) => new PromiseInspection(true, val)).catch((err) => new PromiseInspection(false, err))));
};

// wrapping Promise notation around an ajax call
Promise.ajax = function(options) {
    return new Promise((resolve, reject) =>
        $.ajax(options)
            .done((response) => resolve(response))
            .fail((xhr, status, err) => reject(err))
    );
};
