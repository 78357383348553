const Backbone  = window.Backbone;
const featureManager        = window.featureManager;

const fs = require('fs');
const path = require('path');
const TitleAuthorTemplate = fs.readFileSync(path.join(__dirname, '../../templates/TitleAuthor.html'), 'utf8');

const TitleAuthor = Backbone.View.extend({
    template: _.template(TitleAuthorTemplate),

    className: 'title-author',

    tagName: 'p',

    initialize() {
        if (this.model.get('type').id !== 'periodicals') {
            this.render();
        }
    },

    render() {
        let title = this.model.toJSON();

        if (title.firstCreatorName != null && !_.isEmpty(title.firstCreatorName)) {
            let creatorName = featureManager.isEnabled('fixHtmlTagsInCopy') ? title.firstCreatorName : _.escape(title.firstCreatorName);

            const viewData = {
                href        : window.url(window.routes.searchByType, { type: 'creatorId', query: title.firstCreatorId, sortBy: 'newlyadded' }),
                label       : window.text('cover.searchByAuthor', { author: creatorName }),
                text        : creatorName
            };

            this.$el.html(window.text('byLine', { creator: this.template(viewData) }));
        }else{
            if(!featureManager.isEnabled('no-author-fix')){
                this.$el.html(window.html('cover.noAuthorAvailable'));
            }
        }
    }

});

export default TitleAuthor;
