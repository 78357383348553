import { toStaticUrl } from '../../../lib/assetMapper';

// LHTNG-1817 - Dynamic SVG Loader
export default () => {
    $(() => {
        try {
            delete $.ajaxSettings.headers['csrf-token'];

            $('.svg.dynamic-loader').each((i, el) => {
                let $elm = $(el);
                let src = $elm.attr('data-src');

                if (!_(src).isEmpty() && !$elm.html()) {
                    $.get(toStaticUrl(`img/${src}`)).then((response) => {
                        if (!$elm.html()) {
                            $elm.append($('svg', response));
                        }
                    });
                }
            });
        }
        finally {
            $.ajaxSetup({
                headers: {'csrf-token': $('#csrf').val() || OverDrive._csrf}
            });
        }
    });
};
