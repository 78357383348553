import TitleFormatBadgeTemplate from '../../templates/TitleFormatBadge.html';

const TitleFormatBadge = Backbone.View.extend({
    initialize({typeId}) {
        this.typeId = typeId;
        this.render();
    },

    render() {
        this.setElement($(TitleFormatBadgeTemplate({typeId: this.typeId})));
    }
});

export default TitleFormatBadge;
