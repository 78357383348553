import CoverTemplate from './templates/Cover.html';
import NoCoverTemplate from './templates/NoCover.html';
import { toStaticUrl } from '../../../../lib/assetMapper';

export default class Cover extends Backbone.View {
    constructor({ coverWidths, isInHero, isTitleHistory = false }) {
        super(...arguments);

        this.coverWidths = _.extend(
            { mobile: 145, tablet: 160, desktop: 190 },
            coverWidths
        );
        this.isInHero = isInHero;
        this.isTitleHistory = isTitleHistory;

        this.listenTo(this.model, 'change', this._render);
        this._render();
    }

    remove() {
        $(window).off(`resize.${this.model.id}`);
    }

    _render() {
        const media = this.model.toJSON();
        const coverWidth = bowser.mobile
            ? this.coverWidths.mobile
            : bowser.tablet
            ? this.coverWidths.tablet
            : this.coverWidths.desktop;
        const cover = this.model.getCover(coverWidth);

        if (this.model.isPlaceholder) {
            let $cover = `<div class="CoverImageContainer"></div>`;
            this.$el.empty().append($cover);
        } else if (cover && cover.href) {
            let $cover = $(
                CoverTemplate({
                    media,
                    coverColor:
                        (cover &&
                            cover.primaryColor &&
                            cover.primaryColor.hex) ||
                        'inherit',
                    src: cover && cover.href,
                    href: this.model.titleDetailsUrl,
                    label: window.text('cover.coverImageLabel', {
                        title: media.title,
                        creator: media.firstCreatorName,
                    }),
                    noSampleClass:
                        media.sample.href == null ? 'is-noSample' : '',
                    srcset: cover && cover.srcset,
                })
            );

            // LHTNG-2105: In the event that an image fails to load, wipe out the href's and update the model to trigger a render()
            $cover.find('img').on('error', () => {
                let imagesRemoved = JSON.parse(
                    JSON.stringify(this.model.attributes.covers)
                );

                _(imagesRemoved).each((image) => {
                    delete image.href;
                });

                this.model.set({ covers: imagesRemoved });
            });

            this.$el.empty().append($cover);
        } else if (this.isTitleHistory) {
            this.$el.empty().append(
                `<a href="${this.model.titleDetailsUrl}">
                    <img alt="${window.html('coverImageForTitle', {
                        title: media.title,
                    })}" class="history-noCover" src="${toStaticUrl(
                    'img/no-cover_en_US.jpg'
                )}">
                </a>`
            );
        } else {
            let creatorText = this.model.isMagazine
                ? this.model.get('edition')
                : this.model.get('firstCreatorName');
            this.$el.empty().append(
                NoCoverTemplate({
                    media,
                    href: this.model.titleDetailsUrl,
                    isInHero: this.isInHero,
                    coverWidth: coverWidth,
                    coverHeight: (coverWidth * 293) / 220,
                    creatorText: creatorText,
                })
            );
            $(window).on(
                `resize.${this.model.id}`,
                _.bind(
                    this._manageOverflow,
                    this,
                    '.CoverImageContainer .noCoverAvailable .title',
                    this.model.get('title')
                )
            );
            $(window).on(
                `resize.${this.model.id}`,
                _.bind(
                    this._manageOverflow,
                    this,
                    '.CoverImageContainer .noCoverAvailable .author',
                    creatorText
                )
            );
            $(window).on(
                `resize.${this.model.id}`,
                _.bind(this._resizeClearImage, this)
            );

            // timeout gives browser chance to draw and set innerHTML values used
            setTimeout(() => {
                this._manageOverflow(
                    '.CoverImageContainer .noCoverAvailable .title',
                    this.model.get('title')
                );
                this._manageOverflow(
                    '.CoverImageContainer .noCoverAvailable .author',
                    creatorText
                );
                this._resizeClearImage();
            }, 1);
        }

        return this;
    }

    _resizeClearImage() {
        const image = this.el.querySelector('img');
        if (image) {
            $(image).height(($(image).parent().width() * 293) / 220);
        }
    }
    _manageOverflow(selector, resetText) {
        const element = this.el.querySelector(selector);
        if (element) {
            element.innerHTML = resetText;
            if (selector.indexOf('.title') !== -1) {
                if ($(element).width() < 100) {
                    $(element).css('font-size', '14px');
                } else if (
                    $(element).width() < 125 &&
                    $(element).width() >= 100
                ) {
                    $(element).css('font-size', '16px');
                } else if (
                    $(element).width() < 150 &&
                    $(element).width() >= 125
                ) {
                    $(element).css('font-size', '18px');
                } else if ($(element).width() >= 150) {
                    $(element).css('font-size', '20px');
                }
            } else if (selector.indexOf('.author') !== -1) {
                if ($(element).width() < 110) {
                    $(element).css('font-size', '10px');
                } else if (
                    $(element).width() < 135 &&
                    $(element).width() >= 110
                ) {
                    $(element).css('font-size', '12px');
                } else if (
                    $(element).width() < 160 &&
                    $(element).width() >= 135
                ) {
                    $(element).css('font-size', '14px');
                } else if ($(element).width() >= 160) {
                    $(element).css('font-size', '16px');
                }
            }
            if (
                element.scrollHeight > 0 &&
                element.scrollHeight > element.clientHeight
            ) {
                let text = element.innerHTML;
                text = `${text.replace(/\W+$/g, '')}...`; // remove trailing special characters and add ellipsis
                while (
                    text !== '...' &&
                    element.scrollHeight > element.clientHeight
                ) {
                    text = text.replace(/\W*\w\W*(\.{3})$/g, '$1'); // remove a non-ellipsis character and unwanted characters around itΩ
                    element.innerHTML = text;
                }
                // todo: put a min width an height on this! We know what the heights are for particular screen widths
            }
        }
    }
}
