import SlantedGridTemplate  from './SlantedCollectionHightlight.html';
import Cover from '../media/Cover';
import TitleName from '../media/views/TitleInfo/TitleName';
import ColorUtility from '../../../../lib/colorUtility';
const {OverDrive} = window;


export default class SlantedCollectionHighlight extends Backbone.View {

    template = SlantedGridTemplate;
    constructor({ bus, room, isHero = false, titleCountOverride = null }) {
        super(...arguments);

        this._views = [];
        this.bus         = bus;
        this.room        = room;
        this.isHero      = isHero;
        this.highContrastMode = window.OverDrive.highContrast;
        this.titleCountOverride = titleCountOverride;

        this._vertToHorizBreakpointPx = 720;
        this._coverBlockWidthPercentLarge = 0.58;
        this._coverBlockWidthPercentageSmall = 1;

        this._smallGridMaxWidthPx = 555;
        this._mediumGridMaxWidthPx = 667;
        this._largeGridMaxWidthPx = 1280;
        this._coverPlaceholderHtml = '<div tabindex="-1" class="CoverImageContainer placeholder"></div>';
    }

    render() {
        this.$el.empty();
        let colors = this.getTitlePanelColors();

        let sizeInfo = this.getGridSize();
        this.$el.empty().append(
            $(this.template({
                coverGridSizeClass: sizeInfo.class,
                collectionTitle: this.model.get('name'),
                collectionDescription: this.model.get('description'),
                collectionId: this.model.get('id'),
                coversHtml: this.getCoversHtml(),
                bannerBackgroundColor: colors.background,
                bannerTextColor: colors.foreground,
                isHero: this.isHero,
                useFallbackDisplay: this.useFallbackDisplay,
                fallBackGridWidth: sizeInfo.gridWidthFallback
            })));

            this.$el.find('.textBlockInner a').on('mouseover', (evt) => {
                evt.currentTarget.style.color = colors.foregroundHover;
            }).on('mouseout', (evt) => {
                evt.currentTarget.style.color = colors.foreground;
            });

        return this;
    }

    get desiredTitleCount(){
        if (this.titleCountOverride){
            return this.titleCountOverride;
        }

        let sizeInfo = this.getGridSize();
        return this.useFallbackDisplay ? sizeInfo.numCoversFallback : sizeInfo.numCovers;
    }

    get useFallbackDisplay(){
        return window.Modernizr && !window.Modernizr.cssgrid;
    }

    getGridSize(){

        const xlarge = {
            class: 'extraLarge',
            numCovers: 39,
            numCoversFallback: 47,
            numTilesFallback: 117,
            gridWidthFallback: 13,
            placeholderIndexes: [4,5,6,7,8,9,10,11,12,18,19,20,21,22,23,24,25,26,33,34,35,36,37,38,39,40,48,49,50,51,52,53,54,55,62,63,64,65,66,67,68,69,70,77,78,79,80,81,82,83,84,91,92,93,94,95,96,97,98,99,104,105,106,107,108,109,110,111,112]
        };

        const large = {
            class: 'large',
            numCovers: 25,
            numCoversFallback: 25,
            numTilesFallback: 54,
            gridWidthFallback: 9,
            placeholderIndexes: [0,4,5,6,7,8,14,15,16,17,18,25,26,27,28,35,36,37,38,39,44,45,46,47,48,49,52,53]
        };

        const medium = {
            class: 'medium',
            numCovers: 17,
            numCoversFallback: 21,
            numTilesFallback: 35,
            gridWidthFallback: 7,
            placeholderIndexes: [0,4,5,6,12,13,14,21,22,28,29,30,31]
        };

        const small = {
            class: 'small',
            numCovers: 12,
            numCoversFallback: 20,
            numTilesFallback: 20,
            gridWidthFallback: 5,
            placeholderIndexes: [0,10,15,16]
        };

        // take the max of the available width and height so we don't need to rerender when the device rotates
        let largeDimension = Math.max(window.screen.availWidth, window.screen.availHeight);
        let smallDimension = Math.min(window.screen.availWidth, window.screen.availHeight);

        let landscapeMaxGridWidth = (largeDimension < this._vertToHorizBreakpointPx)
            ? largeDimension * this._coverBlockWidthPercentageSmall
            : largeDimension * this._coverBlockWidthPercentLarge;

        let portraitMaxGridWidth = (smallDimension < this._vertToHorizBreakpointPx)
            ? smallDimension * this._coverBlockWidthPercentageSmall
            : smallDimension * this._coverBlockWidthPercentLarge;

        let maxCoverGridWidth = Math.max(landscapeMaxGridWidth, portraitMaxGridWidth);

        if (maxCoverGridWidth <= this._smallGridMaxWidthPx){
            return small;
        } else if (maxCoverGridWidth <= this._mediumGridMaxWidthPx){
            return medium;
        } else if (maxCoverGridWidth <= this._largeGridMaxWidthPx){
            return large;
        } else {
            return xlarge;
        }
    }

    getTitlePanelColors(){

        let backgroundColor;

        if (this.highContrastMode){
            backgroundColor = ColorUtility.highContrastBlue;
        } else {
            // choose a different cover for the source color when the title block is rendered above the cover grid.
            // The effect is nicer if the source cover is abuts the colored area that matches it.
            let colorSourceTitle = this.model.titleCollection.first();
            if (window.innerWidth < this._vertToHorizBreakpointPx && this.model.titleCollection.length > 2){
                colorSourceTitle = this.model.titleCollection.at(2);
            }

            let cover = colorSourceTitle.getCover(300);
            backgroundColor = '#a79d99';
            if (this.isHero && OverDrive.colors.primary){
                backgroundColor = OverDrive.colors.primary;
            }
            else if (!colorSourceTitle.isPlaceholder && cover && cover.primaryColor){
                backgroundColor = cover.primaryColor.hex;
            } else {
                backgroundColor = '#a79d99';
            }
        }

        let foregroundColor = ColorUtility.getTextColorFromHex(backgroundColor);

        return {
            background: backgroundColor,
            foreground: foregroundColor,
            foregroundHover: ColorUtility.shadeColorHex(backgroundColor, 0.2)
        };
    }

    // Render one cover for each available title and then repeat them to reach the
    // desired cover count
    getCoversHtml(){
        this.renderBaseCovers();
        let sizeInfo = this.getGridSize();

        let numRequired = !this.useFallbackDisplay ? sizeInfo.numCovers : sizeInfo.numTilesFallback;

        let results = [];
        while (results.length < numRequired){
            for (let i = 0; i < this._coverMarkup.length && (results.length < numRequired);){
                if (!this.useFallbackDisplay || !sizeInfo.placeholderIndexes.includes(results.length)){
                    results.push(this._coverMarkup[i]);
                    ++i;
                } else {
                    // The fallback display mode uses placeholder covers to fill out the parts of the grid
                    // that will be clipped off by the parent container.
                    results.push(this._coverPlaceholderHtml);
                }
            }
        }
        return results;
    }

    renderBaseCovers() {
        this._views.forEach((view) => view.remove());
        this._views = [];
        this._coverMarkup = [];
        this.model.titleCollection.each((title) => {

            if (title.isPlaceholder){
                this._coverMarkup.push(this._coverPlaceholderHtml);
            }
            else {
                let cover = new Cover({
                    model: title,
                    coverWidths: {mobile: 200, tablet: 300, desktop: 400},
                    isInHero: this.isHero
                });
                cover.render();

                // we add a hidden title name for screen readers
                let titleName = new TitleName({model: title});

                this._coverMarkup.push(`${cover.el.innerHTML} <h3 class="hiddenTitleName">${titleName.el.innerHTML}</h3>`);

                this._views.push(cover);
                this._views.push(titleName);
            }
        });
    }

    remove() {
        this._views.forEach((v) => v.remove());
        super.remove();
    }

    makeHero() {
        this.isHero = true;
        this.$el.find('.mainBox').addClass('hero');
        let colors = this.getTitlePanelColors();
        this.$el.find('.textBlockOuter').attr('style', `background-color:${colors.background};color:${colors.foreground}`);
    }

    toggleTitleVisibility(isVisible) {
        let title = this.$el.find('.textBlockInner');
        isVisible ? title.removeClass('invisible') : title.addClass('invisible');
    }
}
