import QueryString from 'querystring';

export default class HashManager {

    static parse() {
        let hashObject = QueryString.parse(location.hash.substr(1));

        _.keys(hashObject).forEach((key) => {
            hashObject[key] = _.flatten([hashObject[key] || []]);
        });

        return hashObject;
    }

    static update(object) {
        let newHash = QueryString.stringify(object);
        if (newHash !== '&'){
            location.hash = newHash;
        }
    }

    static get(key) {
        let response = this.parse();

        if (_.isString(key)) {
            return response[key];
        }

        return response;
    }

    static set(key, value) {
        let hash = this.parse();

        hash[key] = _.flatten([value]);

        this.update(hash);
    }

    static push(key, value) {
        let hash = this.parse();

        hash[key] = hash[key] || [];
        hash[key].push(value);

        this.update(hash);
    }

    static remove(key) {
        let hash = this.parse();

        hash[key] && delete hash[key];

        this.update(hash);
    }
}
