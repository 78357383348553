const _ = require('underscore');

// ref: OverDrive.Thunder.Models.DataModels.GeneratedCollectionDetails
module.exports = class GeneratedCollectionDetails {
    constructor() {
        this.mediaType          = '';
        this.language           = '';
        this.publisherEntityId  = null; // int
        this.maturityLevels     = []; // string
        this.subjects           = []; // string
        this.bisacCodes         = [];

        this.sortBy             = '';
        this.maxItems           = undefined; // int

        Object.assign(this, ...arguments);
    }

    // Translates GeneratedCollectionDetails model to parameters that align with the MediaParameters model for executing a "search"
    serializeForSearch() {
        let model = _.extend({}, this);

        // language is an array in MediaParameters
        model.language = _(_.flatten([model.language])).reject((val) => _.isEmpty(val));

        // maturityLevels is called maturityLevel
        model.maturityLevel = _(_.flatten([model.maturityLevels])).reject((val) => _.isEmpty(val));
        delete model.maturityLevels;

        // subjects is called subject
        model.subject = _(_.flatten([model.subjects])).reject((val) => _.isEmpty(val));
        delete model.subjects;

        if (window.featureManager.isEnabled('bisac-collection-filtering')) {
            model.bisacCode = _(_.flatten([model.bisacCodes])).reject((val) => _.isEmpty(val));
        }

        if (model.mediaTypes) {
            if (window.featureManager.isEnabled('media-types-collection-param')) {
                delete model.mediaType;
            } else{
                delete model.mediaTypes;
            }
        }

        if (model.mediaTypes) {
            model.mediaTypes =  model.mediaTypes.join(',');
        } else if (window.featureManager.isEnabled('filter-out-ntc')) {
            model.mediaTypes = window.OverDrive.whitelistedMediaTypes;
        }

        delete model.bisacCodes;  // we need to remove this property regardless because the correct query param is `bisacCode`.  The mismatch between the thunder query params and the property names in the generated collection definitions is...fun.

        return model;
    }
};
