import { toStaticUrl } from '../../../../lib/assetMapper';

export default (isChina = false) => {
    let appLinksUS = () => `
        <div class="row appLinks">
            <div class="small-12 columns apple">
                <a data-ga-action="libby_promo_app" target="_blank" rel="noreferrer" class="LibbyTout-appLink" href='${
                    window.OverDrive.iOSUrl
                }'><img alt='${window.html(
        'appleAppStore'
    )}' src='${toStaticUrl('img/app-store-badge-apple.svg')}'/></a>
            </div>
            <div class="small-12 columns google">
                <a data-ga-action="libby_promo_app" target="_blank" rel="noreferrer" class="LibbyTout-appLink" href='${
                    window.OverDrive.androidUrl
                }'><img alt='${window.html(
        'googlePlayStore'
    )}' src='${toStaticUrl('img/app-store-badge-google.svg')}'/></a>
            </div>
          
        </div>`;

    let appLinksCN = () => `
        <div class="row">
            <div class="small-12 columns apple">
                <a data-ga-action="libby_promo_app" target="_blank" rel="noreferrer" class="LibbyTout-appLink cnAppLink" href='http://zhushou.360.cn/detail/index/soft_id/3890677?recrefer=SE_D_Libby'>360</a>
            </div>
            <div class="small-12 columns google">
                <a data-ga-action="libby_promo_app" target="_blank" rel="noreferrer" class="LibbyTout-appLink cnAppLink" href='http://www.lenovomm.com/app/21633881.html'>Lenovo</a>
            </div>

        </div>`;

    return `
        <div class="LibbyTout row">
            <div class="LibbyTout-logo show-for-medium-up medium-4 columns">
                <div class="libbyIcon-container">
                    <img alt="Part of the Libby Logo" class="libby-head" src="${toStaticUrl(
                        'img/head.svg'
                    )}" />
                    <img alt="Part of the Libby Logo" class="libby-book" src="${toStaticUrl(
                        'img/book.svg'
                    )}" />
                </div>
            </div>

            <div class="LibbyTout-message small-12 medium-5 columns">
                <div class="msgContainer">
                    <h1 class="heading">${window.html('libbyTout.heading')}</h1>
                    <div class="message">${window.html(
                        'libbyTout.message'
                    )}</div>
                    <p class="libbyLink">${text('libbyTout.learnMoreAt', {
                        link: `<a data-ga-action="libby_promo" target="_blank" rel="noreferrer" href="${
                            isChina
                                ? 'https://meet.libbyapp.com/'
                                : 'https://meet.libbyapp.com/?utm_origin=lightning&utm_page_genre=tout&utm_list=meet_libby&utm_content=libby_tout_learnmore_06019018'
                        }">meet.libbyapp.com</a>`,
                    })}</p>
                </div>
            </div>

            <div class="LibbyTout-apps small-12 medium-3 columns">
                ${isChina ? appLinksCN() : appLinksUS()}
            </div>
            <div class="LibbyTout-logo show-for-small-only medium-4 columns">
                <div class="libbyIcon-container libbyIcon-mobile">
                    <img alt="Part of the Libby Logo" class="libby-head" src="${toStaticUrl(
                        'img/head.svg'
                    )}" />
                    <img alt="Part of the Libby Logo" class="libby-book" src="${toStaticUrl(
                        'img/book.svg'
                    )}" />
                </div>
            </div>
        </div>
        <div class="LibbyTout-hide-banner LibbyTout-wayPoint row"></div>`;
};
