export default ({ name, room, seeAllUrl }) => `
<div class="collection-container">
    <div class="Hero-banner ${ !!room && `is-${room.themeName}` }"></div>
    <div class="row">
        <div class="columns small-12 Collection Standard">
            
                <h2 class="collectionTitle Label left invisible" tabindex="-1">
                    <a href="${seeAllUrl}" class="TitleLink Carousel-titleLink ${ !!room && `is-${room.themeName}` }">
                        ${name}
                        <i class="icon-forward-arrow secondary-color"></i>
                    </a>
                </h2>
                <a href="${seeAllUrl}" role="link" aria-label="${ html('carousel.seeAllTitles', { collectionName: name }) }" class="Carousel-seeAll u-allCaps button radius no-outline">
                    ${html('seeAll')}
                </a>
            
            <div class="Titles">
                <ul class="titleContainer"></ul>
            </div>
        </div>
    </div>
</div>`;
