import MediaInterface from '../media/MediaInterface';

import GeneratedCollectionDetails from '../../../../business-objects/GeneratedCollectionDetails';

// ref: Thunder.Models.ResponseModels.CollectionDefinition
export default class CollectionDefinition extends Backbone.Model {

    get idAttribute() { return 'id'; }

    get collectionOptions() {
        let options = {
            includeFacets: false,
            perPage:       this.pageSize || 6,
            shuffle:       this.shuffle || this.isLuckyDay
        };
        if (this.get('sortByAvailability') || this.get('availabilityOption') === 'available-first') {
            options.availableFirst = true;
        }
        if (this.get('showOnlyAvailable') || this.get('availabilityOption') === 'available-only') {
            options.showOnlyAvailable = true;
        }
        if (this.get('showOnlyPrerelease')) {
            options.showOnlyPrerelease = true;
        }
        if (this.useListOrder){
            options.sortBy = 'listorder';
        }

        return options;
    }

    defaults() {
        return {
            id                          : '0',
            name                        : '',
            description                 : '',
            availabilityOption          : '',
            showOnlyAvailable           : false,
            showOnlyPrerelease          : false,
            sortByAvailability          : false,
            curatedCollectionDetails    : null,
            generatedCollectionDetails  : null,
            lastModified                : null,
            order                       : 0,

            // Computed property
            totalItems                  : 0
        };
    }

    get isLuckyDay(){
        return this.get('availabilityOption') === 'available-luckyday';
    }

    get isGenerated() {
        return !_(this.get('generatedCollectionDetails')).isEmpty();
    }

    get isCurated() {
        return !_(this.get('curatedCollectionDetails')).isEmpty();
    }

    get useListOrder(){
        return this.isCurated && this.get('curatedCollectionDetails').isOrdered;
    }

    /**
     * @prop {TitleCollection} collection
     * @memberof CollectionDefinition
     */
    constructor() {
        super(...arguments);

        this._mediaInterface = new MediaInterface();

        this.titleCollection = this._mediaInterface.items;

        this.listenTo(this.titleCollection, 'all', () => {
            this.set('totalItems', this.titleCollection.length);
        });
    }

    fetchCollectionTitles({ patronSettings, room}) {
        // Parse the current room's maturityLevels if there is a room, and a maturityLevels filter has been set
        let roomHasMaturityLevelFilter = !_.isEmpty(room && room.filters && room.filters.maturityLevels);

        let patronMaturityLevelPreferences = this._parsePatronAudienceSettings(patronSettings);

        if (this.isGenerated) {
            let genColDetails = this.get('generatedCollectionDetails');
            if (this.attributes.sortBy) {
                genColDetails.sortBy = this.attributes.sortBy;
            }
            if (!!this.get('showOnlyLuckyDayAvailable')){
                genColDetails.showOnlyLuckyDayAvailable = true;
            }
            let details = new GeneratedCollectionDetails(genColDetails);
            let params  = details.serializeForSearch();

            if (!!patronSettings.showOnlyAvailable) {
                params.showOnlyAvailable = true;
            }

            // If we are in a room with maturity filters applied, the filters will be apart of the collection definition and we can simply query for the collection
            // User preferences should NOT override a room's maturity filter
            if (roomHasMaturityLevelFilter) {
                return this._mediaInterface.fetchCollectionByParameters(params, this.collectionOptions);
            }
            // Otherwise determine if the User's maturity filter settings cover the collection's maturityLevel settings
            else {
                let generatedCollectionOptions = Object.assign({}, this.collectionOptions);

                if (!_(patronMaturityLevelPreferences).isEmpty() && _(params.maturityLevel).isEmpty()) {
                    Object.assign(generatedCollectionOptions, { maturityLevel: patronMaturityLevelPreferences });
                }

                // Fake an empty response if all the auto generated collection's filters are not covered by the user's maturity level settings
                return this._patronFiltersPermitCollection(params.maturityLevel, patronMaturityLevelPreferences)
                    ? this._mediaInterface.fetchCollectionByParameters(params, generatedCollectionOptions)
                    : this._mediaInterface.fetchEmptyResponse();
            }
        }
        // else if "isCurated"
        else {
            let curatedCollectionOptions = Object.assign({}, this.collectionOptions);

            if (!!patronSettings.showOnlyAvailable) {
                curatedCollectionOptions.showOnlyAvailable = true;
            }

            if (window.featureManager.isEnabled('sort-curated-list-carousels') && this.attributes.sortBy) {
                curatedCollectionOptions.sortBy = this.attributes.sortBy;
            }

            // If we are NOT in a room, apply a user's maturity settings if present to the collection request
            // The expectation is that if none of the content satisfies the user's filter its acceptable that no results are returned
            if (_(room).isEmpty()) {
                Object.assign(curatedCollectionOptions, { maturityLevel: patronMaturityLevelPreferences });
            }

            return this._mediaInterface.fetchCollectionById(this.id, curatedCollectionOptions);
        }
    }

    // Convert the patron audience settings model into an array of filter terms that MediaInterface understands, ex:
    // "audience-setting": {
    //     "all"            : false,
    //     "juvenile"       : false,
    //     "youngadult"     : true,
    //     "generalcontent" : true,
    //     "adultonly"      : true
    // }
    // to: ["youngadult", "generalcontent", "adultonly"]
    /** @returns {string[]} */
    _parsePatronAudienceSettings(patronSettings) {
        let patronAudienceSetting = patronSettings && patronSettings['audience-setting'] || {};

        if (_(patronAudienceSetting).isEmpty() || patronAudienceSetting.all) {
            return [];
        }

        let audienceFilters = [];
        _(patronAudienceSetting).each((isApplied, key) => {
            if (isApplied && key !== "all") {
                audienceFilters.push(key);
            }
        });

        return audienceFilters;
    }

    _patronFiltersPermitCollection(collectionMaturityFilters = [], userMaturityFilters = []) {
        if (userMaturityFilters.length === 0) {
            return true;
        }

        const collectionLower = collectionMaturityFilters.map((filter) => filter.toLowerCase());
        const userLower = userMaturityFilters.map((filter) => filter.toLowerCase());

        return _(collectionLower).difference(userLower).length === 0;
    }
}
