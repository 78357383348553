import { toStaticUrl } from '../../../../../lib/assetMapper';

export default ({ media, href, isInHero, coverWidth, coverHeight, creatorText }) => `
<div class="CoverImageContainer">
<a class="CoverImageContainer noCoverAvailable ${ isInHero ? 'secondary-background' : 'primary-background' }"
    href="${ href }"
    tabindex="-1"
    data-href    ="${ href }"
    data-media-id="${ media.id }"
    data-title   ="${ media.title }"
    data-type    ="${ media.type.id }">
        <div class="title ${ isInHero ? 'secondary-font-highlight-box' : 'primary-font primary-font-highlight-box' }">${ media.title }</div>
        <div class="author ${ isInHero ? '' : 'primary-font' }">${ creatorText }</div>

        <img
            tabindex="-1"
            aria-label="${ media.title } - ${ html(media.type.id) }"
            tabindex="0"
            class="large-title js-details-cover-image"
            src="${ toStaticUrl('img/clear.gif') }"
            alt="${ media.title } - ${ html(media.type.id) }"
            />

</a>
</div>

`;
