import Title from '../components/media/models/Title';
import TitleCollection from '../components/media/models/TitleCollection';

// This is a replacement for PatronMediaItemsModel (ecmascript\models\patron_media_items.js)
// We should gradually transition to using this as time permits and we can take the time to validate funtionality
export default class PatronTitleStatus extends Backbone.Model {
    get url() {
        return window.url(window.routes.mediaInfo);
    }

    fetchTitleStatus(titleCollection, statusTypes = null) {
        let titleArray = titleCollection.models || titleCollection || [];

        if (titleArray.length === 0) {
            return Promise.resolve();
        }

        let titleIds = _.map(_(titleArray).pluck('id'), Number);

        this.request = this.fetch({
            data: JSON.stringify({ titleIds, statusTypes }),
            type: 'POST',
            contentType: 'application/json',
        });

        return this.request;
    }

    /** @param {Title} title */
    updateTitleState(title) {
        title.updatePatronState(this.attributes);
    }

    get titleIds() {
        return _.union(
            this.get('onReadingHistory'),
            _.keys(this.get('starRatings')),
            this.get('onCheckouts'),
            this.get('onHolds'),
            this.get('onWishlist')
        );
    }

    /** @param {TitleCollection} titleCollection */
    updateTitleCollectionState(titleCollection) {
        // we provide an empty array for any status attributes that were not fetched
        // in order to avoid breaking anything downstream
        this.stubAttributes();
        this.titleIds.forEach((titleId) => {
            const model = titleCollection.findWhere({ id: titleId });

            if (model) {
                this.updateTitleState(model);
            }
        });
    }

    stubAttributes() {
        if (!this.get('onReadingHistory')) {
            this.set('onReadingHistory', []);
        }
        if (!this.get('starRatings')) {
            this.set('starRatings', []);
        }
        if (!this.get('onCheckouts')) {
            this.set('onCheckouts', []);
        }
        if (!this.get('onHolds')) {
            this.set('onHolds', []);
        }
        if (!this.get('onWishlist')) {
            this.set('onWishlist', []);
        }
    }
}
