export default ({ geoIdcClass }) => `
<div class="title-contents card TitleCard ${geoIdcClass}">
    <div class="TitleHeaderBar"></div>

    <div class="CoverImage"></div>

    <div class="InfoPanel">
        <div class="TitleInfo"></div>

        <div class="TitleAction is-grid"></div>

        <div class="TitleWishlist is-grid"></div>
    </div>

    <div class="clear" />
</div>`;
