import TitleCollectionView from '../../media/TitleCollectionView';
import CollectionHighlightTemplate from '../templates/collection_highlight.html';

export default class CollectionHighlight extends Backbone.View {
    template = CollectionHighlightTemplate;

    viewAttributes(collection) {
        let classes = [];

        if (this.isHero) {
            classes.push(`large-block-grid-${collection.length}`);
        } else {
            classes.push(`large-block-grid-6`);
        }

        if (collection.length <= 2) {
            classes.push(`small-block-grid-${collection.length}`);
        }

        if (collection.length >= 3) {
            classes.push(`small-block-grid-2 medium-block-grid-3`);
        }

        return {
            class: `titleContainer ${classes.join(' ')}`,
        };
    }

    constructor({ bus, room, isHero = false }) {
        super(...arguments);

        this.bus = bus;
        this.room = room;
        this.collection = this.model.titleCollection;
        this.isHero = isHero;

        this.render();
    }

    get seeAllUrl() {
        const collectionId = this.model.get('id');
        return window.url(window.routes.collectionById, { collectionId });
    }

    get desiredTitleCount() {
        return 6;
    }

    render() {
        let $view = $(
            this.template(
                _.extend({}, this.model.attributes, {
                    room: this.room,
                    seeAllUrl: this.seeAllUrl,
                })
            )
        );

        this.titleCollectionView && this.titleCollectionView.remove();

        this.titleCollectionView = new TitleCollectionView({
            el: $view.find('.titleContainer'),
            bus: this.bus,
            attributes: this.viewAttributes(this.collection),
            collection: this.collection,
            coverWidths: { mobile: 145, tablet: 160, desktop: 190 },
            isInHero: this.isHero,
            context: {
                collectionDefinition: this.model,
                room: this.room,
            },
        });

        // ref: LHTNG-1970 - hiding the last two elements so we can remove the overflow hidden on the parent container
        $view.find('.Item').each((i, el) => {
            if (i > 3) {
                $(el).addClass(`titles-item-overflow`);
            }
        });

        this.$el.empty().append($view);
        if (this.isHero) {
            this.makeHero();
        }

        return this;
    }

    makeHero() {
        if (!window.featureManager.isEnabled('geo-idc')) {
            this.isHero = true;
            this.$el.find('.collection-container').addClass('Hero');
            this.$el.find('.icon-forward-arrow').remove();
            let description = this.model.attributes.description;
            if (description) {
                this.$el
                    .find('.collectionTitle')
                    .after(
                        `<p class="Hero-collectionDescription">${description}</p>`
                    );
            }
        }
    }

    remove() {
        this.titleCollectionView && this.titleCollectionView.remove();
        super.remove();
    }

    toggleTitleVisibility(isVisible) {
        let title = this.$el.find('.collectionTitle');
        isVisible
            ? title.removeClass('invisible')
            : title.addClass('invisible');
    }
}
